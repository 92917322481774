import {
  FETCH_CAROUSEL,
  FETCH_CAROUSEL_SUCCESS,
  FETCH_CAROUSEL_FAILED,
  EDIT_CAROUSEL
} from "../store/types";
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, update, set, child, remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchCarousel = () => (dispatch) => {
  
  const {
    carouselRef
  } = firebase;

  dispatch({
    type: FETCH_CAROUSEL,
    payload: null
  });

  onValue(carouselRef, snapshot => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i]
      });
      dispatch({
        type: FETCH_CAROUSEL_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_CAROUSEL_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_carousel
      });
    }
  });
};


export const editCarousel = (carousel,method) => async (dispatch) => {

  const {
    carouselRef,
    carouselEditRef,
    carouselImageRef
  } = firebase;

  var carId = push(carouselRef).key;

  dispatch({
    type: EDIT_CAROUSEL,
    payload: { method, carousel }
  });
  
  if (method === 'Add') {
    if(carousel.addimage){
      await uploadBytesResumable(carouselImageRef(carId),carousel.image);
      let image = await getDownloadURL(carouselImageRef(carId));
      let data = carousel;
      data.image = image;
      data.id = carId;
      data.createdAt = new Date().getTime()
      set(child(carouselRef, carId),data )
    }
  } else if (method === 'Delete') {
    remove(carouselEditRef(carousel));
  } else if (method === 'Edit') {
    if(carousel.addimage){
      await uploadBytesResumable(carouselImageRef(carousel.id),carousel.image);
      let image = await getDownloadURL(carouselImageRef(carousel.id));
      let data = carousel;
      data.image = image;
      set(carouselEditRef(carousel.id),data);
    }else{
      set(carouselEditRef(carousel.id),carousel);
    }
  }
   else {
      set(carouselEditRef(carousel.id),carousel);
  }
}
