import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, Card, TextField, Grid, Avatar } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { makeStyles } from "@mui/styles";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { useParams } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const useStyles = makeStyles((theme) => ({
    textField: {
        "& label.Mui-focused": {
            color: MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: MAIN_COLOR,
            },
        },
    },
    rootRtl: {
        "& label": {
            right: 10,
            left: "auto",
            paddingRight: 20
        },
        "& legend": {
            textAlign: "right",
            marginRight: 20
        },
        "& label.Mui-focused": {
            color: MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: MAIN_COLOR,
            },
        },
    },
    rootRtl_3: {
        "& label": {
            right: 0,
            left: "auto",
            paddingRight: 20
        },
        "& legend": {
            textAlign: "right",
            marginRight: 20
        },
        "& label.Mui-focused": {
            color: MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: MAIN_COLOR,
            },
        },
    },
    rootRtl_2: {
        "& label": {
            right: 5,
            left: "auto",
            paddingRight: 20
        },
        "& legend": {
            textAlign: "right",
            marginRight: 20
        },
        "& label.Mui-focused": {
            color: MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: MAIN_COLOR,
            },
        },
    },
    rootRtl_1: {
        "& label": {
            right: 10,
            left: "auto",
            paddingRight: 20
        },
        "& legend": {
            textAlign: "right",
            marginRight: 15
        },
        "& label.Mui-focused": {
            color: MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: MAIN_COLOR,
            },
        },
    },

    selectField_rtl: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: MAIN_COLOR,
        },
        "& label": {
            right: 0,
            left: "auto",

        },
        "& legend": {
            textAlign: "right",
            marginRight: 35,
        },
    },

    right: {
        textAlign: "right", right: 0, left: "auto", paddingRight: 40
    },
    right_1: {
        textAlign: "right", right: 0, left: "auto", paddingRight: 45
    },

    selectField: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: MAIN_COLOR,
        },
    },
}));

function EditCarousel() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const fileInputRef = useRef();
    const loaded = useRef(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { editCarousel, fetchUsersOnce } = api;
    //const auth = useSelector((state) => state.auth);
    //const settings = useSelector((state) => state.settingsdata.settings);
    const carouselData = useSelector(state => state.carousel.carouseldata);
    const [data, setData] = useState(null);
    //const [oldData, setOldData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [carouselImage, setCarouselImage] = useState(null);
    const [imageAdd, setImageAdd] = useState(false);
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

    useEffect(() => {
        dispatch(fetchUsersOnce());
    }, [dispatch, fetchUsersOnce]);

    useEffect(() => {
        if (id && carouselData) {
            if (carouselData) {
                const carousel = carouselData.filter(
                    (carousel) => carousel.id === id.toString()
                )[0];
                if (!carousel) {
                    navigate("/404");
                }
                setData(carousel);
                //setOldData(carousel);
            }
        } else {
            setData([]);
        }
        loaded.current = true;
    }, [carouselData, id, navigate]);

    useEffect(() => {
        dispatch(fetchUsersOnce());
    }, [dispatch, fetchUsersOnce]);

    const handleBackNavigation = ()=>{
        navigate("/carousel");
    }

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.id]: e.target.value });
    };

    const handleUpdate = () => {
        if (!(data.heading)) {
            setCommonAlert({ open: true, msg: t("proper_input_name") });
        } else {
            setLoading(true);
            new Promise((resolve) => {
                setLoading(true);
                setTimeout(() => {
                    if(!!carouselImage){
                        data["image"] = carouselImage ;
                    } 
                    data["addimage"] = imageAdd;             
                    dispatch(editCarousel(data, "Edit"));
                    dispatch(fetchUsersOnce());
                    handleBackNavigation()
                    setLoading(false);
                    resolve();
                });
            }).catch((e) => {
                console.error("caught an error:",e)
            }).finally(() => {
                setLoading(false);
            });
        }
        loaded.current = true;
    };

    const handleAdd = () => {
        if (!(data.heading)) {
            setCommonAlert({ open: true, msg: t("proper_input_name") });
        } else if(!carouselImage){
            setCommonAlert({ open: true, msg: t("upload_carousel_image") });
        } else {
            new Promise((resolve) => {
                setLoading(true);
                setTimeout(() => {
                    data["image"] = carouselImage;
                    data["addimage"] = imageAdd;   
                    dispatch(editCarousel(data, "Add"));
                    dispatch(fetchUsersOnce());
                    handleBackNavigation()
                    setLoading(false);
                    resolve();
                });
            }).catch((e) => {
                console.error("caught an error:",e)
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const carouselImageChange = async (e) => {
        setImageAdd(true);
        setCarouselImage(e.target.files[0]);
    };

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({ open: false, msg: "" });
    };

    return loading ? (
        <CircularLoading />
    ) : (
        <div>
            <Card
                style={{
                    borderRadius: "19px",
                    backgroundColor: "#fff",
                    minHeight: 100,
                    maxWidth: "75vw",
                    marginTop: 20,
                    marginBottom: 20,
                    padding: 20,
                    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
            >
                <Typography
                    variant="h5"
                    style={{
                        margin: "10px 10px 0 5px",
                        textAlign: isRTL === "rtl" ? "right" : "left",
                    }}
                >
                    {id ? (t("update_carousel_title")) : (t("add_carousel_title"))}
                </Typography>
                <div
                    dir={isRTL === "rtl" ? "rtl" : "ltr"}
                >
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <Typography
                            style={{
                                margin: "10px 10px 0 5px",
                                textAlign: isRTL === "rtl" ? "right" : "left",
                                fontWeight: "bold",
                                color: MAIN_COLOR,
                                marginBottom: 30
                            }}
                        >
                            {`<<- ${t("go_back")}`}
                        </Typography>
                    </Button>
                </div>

                <Grid
                    container
                    sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr", }}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} display={"flex"} justifyContent={"center"} >
                        <div style={{ width: 200, height: 250 }}>
                            { carouselImage ? (
                                <div
                                    onClick={() => fileInputRef.current.click()}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <img
                                        src={URL.createObjectURL(carouselImage)}
                                        alt="image"
                                        style={{
                                            width: 250,
                                            height: 180,
                                            borderRadius: "19px",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    onClick={() => fileInputRef.current.click()}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                >

                                    {data && data.image ?

                                    <img
                                        alt="Profile"
                                        src={data.image}
                                        style={{ width: 250, height: 180, borderRadius: "5%" }}
                                    />

                                    :
                                    <Avatar
                                        sx={{
                                            width: 250,
                                            height: 180,
                                            display: "flex",
                                            flexDirection: "column",
                                            boxShadow: 3,
                                            border: "2px dashed #B5B5B0",
                                            fontSize: 16,
                                            background: "none",
                                            color: "inherit",
                                            fontWeight: "bold",
                                        }}
                                        variant="square"
                                    >
                                        <FileUploadIcon
                                            sx={{
                                                fontSize: 100,
                                                marginBottom: 3,
                                                color: "grey",
                                            }}
                                        />
                                        {t("upload_carousel_image")}
                                    </Avatar>
                                    }
                                    
                                </div>
                            )}
                            <input
                                onChange={(event) => carouselImageChange(event)}
                                multiple={false}
                                ref={fileInputRef}
                                type="file"
                                hidden
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                display: "flex",
                                gridTemplateColumns: "50%",
                                rowGap: "20px",
                                marginY: 1,
                                direction: isRTL === "rtl" ? "rtl" : "ltr",
                            }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField
                                    label={t("heading")}
                                    id="heading"
                                    value={data?.heading || ""}
                                    fullWidth
                                    onChange={handleInputChange}
                                    className={isRTL === "rtl" ? classes.rootRtl_1 : classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField
                                    label={t("description")}
                                    id="description"
                                    value={data?.description || ""}
                                    fullWidth
                                    onChange={handleInputChange}
                                    className={isRTL === "rtl" ? classes.rootRtl_1 : classes.textField}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    style={{
                                        borderRadius: "19px",
                                        backgroundColor: MAIN_COLOR,
                                        minHeight: 50,
                                        textAlign: "center",
                                        width: ("90%")
                                    }}
                                    onClick={id ? handleUpdate : handleAdd}
                                    variant="contained"
                                >
                                    <Typography
                                        style={{
                                            color: "white",
                                            textAlign: "center",
                                            fontSize: 16,
                                        }}
                                    >
                                        { id ? t("update") : t("add")}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
                {commonAlert.msg}
            </AlertDialog>
        </div>
    );
}

export default EditCarousel