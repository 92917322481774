import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { useTranslation } from "react-i18next";
import AlertDialog from "../components/AlertDialog";
import {SECONDORY_COLOR } from "../common/sharedFunctions";
import { colors } from "../components/Theme/WebTheme";
import { useNavigate } from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image';

export default function Carousel() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    editCarousel
  } = api;
  const columns = [
    { title: t('image'),  field: 'image',cellStyle:{ textAlign: 'center'},
    render: (rowData) =>
        rowData.image ? (
          <img
            alt="Profile"
            src={rowData.image}
            style={{ width: 100, height: 60, borderRadius: "5%" }}
          />
        ) : (
          <ImageIcon sx={{ fontSize: 40 }} />
        ),
      editable: "never"
    },
    { title: t('heading'), field: 'heading'},
    { title: t('description'), field: 'description'}
  ];
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const carousel = useSelector(state => state.carousel);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (carousel.carouseldata) {
      setData(carousel.carouseldata);
    } else {
      setData([]);
    }
  }, [carousel.carouseldata]);

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  return (
    carousel.loading ? <CircularLoading /> :
    <div ref={rootRef}>
      <MaterialTable
        // title={t('carousel')}
        title={t("carousel")}
        columns={columns}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
          padding: "20px",
        }}
        data={data}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
            border: "1px solid rgba(224, 224, 224, 1)",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',          
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
          cellStyle: {
            border: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}


        editable={{
            onRowDelete: oldData =>
              settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(editCarousel(oldData.id, "Delete"));
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
          }}
          actions={[
            {
              icon: 'add',
              tooltip: t("add_fleetadmin"),
              isFreeAction: true,
              onClick: (event) => navigate("/carousel/addcarousel")
            },
            {
              icon: 'edit',
              tooltip: t("edit"),
              onClick: (event,rowData) => navigate(`/carousel/editcarousel/${rowData.id}`)
            }
          ]}
      />
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}
