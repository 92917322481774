import {
  FETCH_CAROUSEL,
  FETCH_CAROUSEL_SUCCESS,
  FETCH_CAROUSEL_FAILED,
  EDIT_CAROUSEL
} from "../store/types";

const INITIAL_STATE = {
  carouseldata: null,
  loading: false,
  error: {
    flag: false,
    msg: null
  }
}

export const carouselreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CAROUSEL:
      return {
        ...state,
        loading: true
      };
    case FETCH_CAROUSEL_SUCCESS:
      return {
        ...state,
        carouseldata: action.payload,
        loading: false
      };
    case FETCH_CAROUSEL_FAILED:
      return {
        ...state,
        carouseldata: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload
        }
      };
    case EDIT_CAROUSEL:
      return state;
    default:
      return state;
  }
};