export const FirebaseConfig = {
	"projectId": "marsal-a86d1",
	"appId": "1:757657485701:web:b311a80520c5fc2d9e84ec",
	"databaseURL": "https://marsal-a86d1-default-rtdb.firebaseio.com",
	"storageBucket": "marsal-a86d1.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCeIp3wvSWJeVtpXNQGnpNcmbGxyK4GnZs",
	"authDomain": "marsal-a86d1.firebaseapp.com",
	"messagingSenderId": "757657485701",
	"measurementId": "G-57L7CS7N81"
};